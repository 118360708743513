.bulk-assignment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1px;
  width: 290px;
  min-height: 226px;
  margin-left: 33px;
  margin-top: -83px;

  border: 2px solid #E2E2E2;
  filter: drop-shadow(5px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 0 5px 5px 5px;

  &.individual {
    margin-top: -120px;
  }

  &_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px 10px;
    gap: 8px;
    width: 285px;
    height: 34px;
    background: #F7F8F9;
    border-radius: 0 3px 0 0;
    text-overflow: ellipsis;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  &_symbol {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    width: 12px;
    height: 16px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  &_name {
    width: 100%;
    height: 16px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  &_arrow {
    position: absolute;
    width: 80px;
    height: 80px;
    background: #ffffff;
    transform: rotate(45deg);
    margin-top: 49px;
    margin-left: 2px;
    z-index: -1;

    &.individual {
      margin-top: 85px; /* This will override the default for individual assignments */
    }
  }

  &_content_label {
    width: 285px;
    height: 19px;
    background: #F7F8F9;
    border-bottom: 1px solid #E2E2E2;
    text-indent: 2.5em;
    box-sizing: border-box;
    display: inline;
    flex-direction: row;
    align-items: center;
    color: #6c6969;
    font-weight: 400;
    font-size: 12px;
  }

  &_tabs {
    background-color: #E2E2E2;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 10px 0 10px;
    height: 30px;
  }

  .tabs_active {
    background-color: #FFFFFF;
    color: #1976D2;
    border-radius: 8px 8px 0 0;
    margin: 0 3px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 12px;;
    white-space: nowrap;
  }

  .tabs_inactive {
    background-color: dimgrey;
    color: #FFFFFF;
    border-radius: 8px 8px 0 0;
    margin: 0 3px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    white-space: nowrap;
  }

  &_content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 10px 20px;
    gap: 10px;
    width: 280px;
    min-height: 129px;
    background: #FFFFFF;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
  }

  &_button {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 15px;
    gap: 8px;
    width: 86px;
    height: 77px;
    border: 1px solid #1976D2 !important;
    border-radius: 10px !important;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  &_close {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 280px;
    height: 40px;
    background: #FFFFFF;
    flex: none;
    order: 6;
    align-self: stretch;
    flex-grow: 0;
  }

  &_close_button {
    padding: 3px 20px 15px 7px;
    width: 24px;
    height: 24px;
    background: #F0F1F2;
    border-radius: 20px;
    border-width: 0;
    cursor: pointer;
  }
}

.asset-custom-fields {
  &_box {
    margin-top: -20px;
  }

  &_text {
    height: 50px;
    margin-left: 10px;
  }

  &_title {
    text-align: center;
    font-size: 13px;
    margin-bottom: 8px;
    font-weight: 350;
    font-family: 'Roboto', sans-serif;
    color: #000000;
  }

  &_list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &_label {
    text-indent: -1em;
    margin-top: 5px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 13px;
    height: 13px;
    font-family: 'Roboto', serif;
    font-style: normal;
    line-height: 14px;
    color: #0a0a0a;
  }

  &_input {
    width: 260px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    margin-left: -15px;
  }

  &_button {
    text-align: center;
    display: inline-flex;
    align-items: center;
    width: 80px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 300;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s;
    text-transform: uppercase;
    justify-content: center;
  }

  &_continue {
    padding: 5px 12px;
    background-color: #1976D2;
    color: #fff;
  }

  &_save {
    padding: 5px 12px;
    background-color: #1976D2;
    color: #fff;
  }

  &_cancel {
    padding: 5px 18px;
    background-color: #D3D3D3;
  }
}