@import "src/styles/colors";
@import "src/styles/typography";

.group-item-presenter {
	display: flex;
	border-bottom: $tabContentBorderColour 1px dotted;
	justify-content: space-between;
	padding: 3px 5px 3px 10px;
	align-items: center;
	cursor: pointer;

	&:hover {
		background-color: $highlightPanelColour;
	}

	&_label {
		@extend .text-small;
		padding-left: 15px;
		margin-right: auto;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		display: flex;
		align-items: center;

		&_icon {
			svg {
				margin-top: -4px;
				margin-bottom: -3px;
			}
		}

		&.is-clickable {
			cursor: pointer;
		}
	}

	&_props {
		margin-right: 10px;

		&.is-clickable {
			cursor: pointer;
		}

		&.disabled {
			cursor: not-allowed;
		}
	}

	&_actions {
		display: flex;
		align-items: center;

		> svg {
			cursor: pointer;
		}

		&_button {
			width: 25px;
			height: 25px;
			border-radius: 15px;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;

			&.disabled {
				cursor: not-allowed;
			}

			&:hover {
				background-color: rgba(255, 255, 255, 0.5);
			}
		}
	}

	&.is-hover {
		background-color: $highlightPanelColour;
	}

	&:last-child {
		margin-bottom: 4px;
	}
}