.icon-as-built {
	position: relative;

	&_signature {
		position: absolute;
		transform: rotate(30deg);
		color: red;
		font-weight: bold;
		font-size: 8px;
		white-space: nowrap;
		width: 20px;
		height: 20px;
		right: 15px;
		bottom: 2px;
	}
}

.material-icon-container {
	position: relative;
	display: inline-block;
}

.material-add-button {
	position: absolute;
	top: -2px;
	right: -4px;
}